import { WorkJobService } from '@/core/services/api/workjob.service';
import {
  WorkJobCreateDto,
  WorkJobDto,
  WorkJobEditDto,
  WorkJobGetAllDto,
  WorkJobPageDto,
  WorkJobPageQueryParamsDto,
} from '@/domain/workjob/dtos/workjob.dto';
import { WorkJobRepository } from '@/domain/workjob/repositories/workjob.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkJobRepositoryService implements WorkJobRepository {
  constructor(private workJobService: WorkJobService) {}
  create(workJob: WorkJobCreateDto): Observable<WorkJobDto> {
    return this.workJobService.create(workJob);
  }

  getPage(params: WorkJobPageQueryParamsDto): Observable<WorkJobPageDto> {
    return this.workJobService.getPage(params);
  }

  getAll(): Observable<WorkJobGetAllDto> {
    return this.workJobService.getAll();
  }

  update(workJob: WorkJobEditDto): Observable<WorkJobDto> {
    return this.workJobService.update(workJob);
  }

  delete(workJob: { id: number }): Observable<WorkJobDto> {
    return this.workJobService.delete(workJob);
  }
}
