import { PageHeader } from '@/shared/utils/constants/page-header.constants';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderDataService {
  private pageHeaderSource = new BehaviorSubject<PageHeader>({
    title: '',
    breadCrumbs: [],
    backRoute: '',
  });

  private builtBackRouteSource = new BehaviorSubject<string>('');

  public pageHeader$ = this.pageHeaderSource.asObservable();

  public builtBackRoute$ = this.builtBackRouteSource.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // Se escucha el evento de cambio de ruta.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          // Se obtiene la configuración de la ruta actual.
          const { pageHeader, params } = this.getActivatedRouteConfig(
            this.activatedRoute
          );

          // Se actualiza la configuración de la cabecera de la página.
          this.pageHeaderSource.next(pageHeader);

          // Se construye la ruta de regreso.
          const backRoute = this.buildBackRoute(pageHeader.backRoute, params);

          // Se actualiza la ruta de regreso.
          this.builtBackRouteSource.next(backRoute);
        })
      )
      .subscribe();
  }

  /**
   *  Obtiene la configuración de la ruta actual.
   *
   * @param route - Ruta actual.
   */
  private getActivatedRouteConfig(route: ActivatedRoute) {
    let currentRoute = route;
    let params: Params = { ...route.snapshot.params };

    // Se obtienen los parametros de las rutas hijas.
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = { ...params, ...currentRoute.snapshot.params };
    }

    const pageHeader: PageHeader = currentRoute.snapshot.data['pageHeader'] || {
      route: '',
      title: '',
      breadCrumbs: [],
      backRoute: '',
    };

    return { pageHeader, params };
  }

  /**
   *  Construye la ruta de regreso con un string y los parametros.
   *
   * @param backRoute - String de configuracion de la ruta de regreso.
   * @param params - Parametros de la ruta actual.
   */
  private buildBackRoute(
    backRoute: string | undefined,
    params: Params
  ): string {
    if (!backRoute) return '';

    // Se reemplazan los parametros de la ruta de regreso.
    const backRouteWithParams = backRoute
      .split('/')
      .map((segment) => {
        // Si el segmento empieza con ':' se reemplaza por el valor del parametro.
        if (segment.startsWith(':')) {
          const paramName = segment.substring(1);
          const paramValue = params[paramName];
          return paramValue ? paramValue : segment;
        }
        return segment;
      })
      .join('/');
    return backRouteWithParams;
  }
}
