import { Routes } from '@angular/router';
import { AppLayoutComponent } from './presentation/templates/layout/layout.component';
import { LoginRedirectGuard } from './core/guards/login-redirect.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./presentation/pages/admin/admin.routes').then(
        (m) => m.adminRoutes
      ),
  },
  {
    path: 'auth',
    loadComponent: () =>
      import(
        './presentation/templates/layout-splash/layout-splash.component'
      ).then((m) => m.RppLayoutSplashComponent),
    canActivate: [LoginRedirectGuard],
    loadChildren: () =>
      import('./presentation/pages/auth/auth.routes').then((m) => m.authRoutes),
  },
  {
    path: 'error',
    loadComponent: () =>
      import(
        './presentation/templates/layout-splash/layout-splash.component'
      ).then((m) => m.RppLayoutSplashComponent),
    loadChildren: () =>
      import('./presentation/pages/errors/error.routes').then(
        (m) => m.errorRoutes
      ),
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import(
        './presentation/templates/layout-splash/layout-splash.component'
      ).then((m) => m.RppLayoutSplashComponent),
    loadChildren: () =>
      import('./presentation/pages/unauthorized/unauthorized.routes').then(
        (m) => m.unauthorizedRoutes
      ),
  },
  { path: '**', redirectTo: 'error/404' },
];
