import { AuditService } from '@/core/services/api/audit.service';
import {
  AuditPageDto,
  AuditPageQueryParamsDto,
  AuditShowDto,
} from '@/domain/audit/dtos/audit.dto';
import { AuditRepository } from '@/domain/audit/repositories/audit.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditRepositoryService implements AuditRepository {
  constructor(private auditService: AuditService) {}

  getPage(
    auditListQueryParams: AuditPageQueryParamsDto
  ): Observable<AuditPageDto> {
    return this.auditService.getPage(auditListQueryParams);
  }

  show(auditId: number): Observable<AuditShowDto> {
    return this.auditService.show(auditId);
  }
}
