import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  UserCreateDto,
  UserDto,
  UserEditDto,
  UserEditStateDto,
  UserPageQueryParamsDto,
  UserPageDto,
  UserShowDto,
  UserGetAllDto,
} from '@/domain/user/dtos/user.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('user/person');
  }

  create(user: UserCreateDto): Observable<UserDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<UserDto>(queryUrl, user);
  }

  getAll(params: any = {}): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<UserGetAllDto>(queryUrl, {
      params: { ...params, take: 999 },
    });
  }

  getPage(params: UserPageQueryParamsDto): Observable<UserPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<UserPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  updateState(user: UserEditStateDto): Observable<UserDto> {
    const queryUrl = `${this.getApiUrl()}/${user.id}/state`;
    return this.http.patch<UserDto>(queryUrl, user);
  }

  show(userId: number): Observable<UserShowDto> {
    const queryUrl = `${this.getApiUrl()}/show/${userId}`;
    return this.http.get<UserShowDto>(queryUrl);
  }

  update(user: UserEditDto): Observable<UserDto> {
    const queryUrl = `${this.getApiUrl()}/${user.id}`;
    return this.http.put<UserDto>(queryUrl, user);
  }
}
