import { RoleService } from '@/core/services/api/role.service';
import { RoleGetAllDto } from '@/domain/role/dtos/role.dto';
import { RoleRepository } from '@/domain/role/repositories/role.repository';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleRepositoryService implements RoleRepository {
  constructor(private roleService: RoleService) {}

  getAll(): Observable<RoleGetAllDto> {
    return this.roleService.getAll();
  }
}
