import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  WorkJobCreateDto,
  WorkJobDto,
  WorkJobEditDto,
  WorkJobGetAllDto,
  WorkJobPageDto,
  WorkJobPageQueryParamsDto,
} from '@/domain/workjob/dtos/workjob.dto';

@Injectable({
  providedIn: 'root',
})
export class WorkJobService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('work/job');
  }

  create(workStation: WorkJobCreateDto): Observable<WorkJobDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<WorkJobDto>(queryUrl, workStation);
  }

  getPage(params: WorkJobPageQueryParamsDto): Observable<WorkJobPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<WorkJobPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getAll(): Observable<WorkJobGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<WorkJobGetAllDto>(queryUrl, {
      params: {
        take: '999',
        orderField: 'Name',
        orderSort: 'asc',
      },
    });
  }

  update(workStation: WorkJobEditDto): Observable<WorkJobDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.put<WorkJobDto>(queryUrl, workStation);
  }

  delete(workStation: { id: number }): Observable<WorkJobDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.delete<WorkJobDto>(queryUrl);
  }
}
