import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportGetProductQueryParamsDto,
  ResponseReportDto,
} from '@/domain/report/dtos/report.dto';
import { ReportProductDownloadRepository } from '@/domain/report/repositories/report-download-product.repository';
import { ReportProductDownloadService } from '@/core/services/api/report-product-download.service';

@Injectable({
  providedIn: 'root',
})
export class ReportProductDownloadRepositoryService
  implements ReportProductDownloadRepository
{
  constructor(
    private reportProductDownloadService: ReportProductDownloadService
  ) {}

  getProductDownload(
    reportListQueryParams: ReportGetProductQueryParamsDto
  ): Observable<ResponseReportDto> {
    return this.reportProductDownloadService.getData(reportListQueryParams);
  }
}
