import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportGetProductQueryParamsDto,
  ResponseReportDto,
} from '@/domain/report/dtos/report.dto';
import { ReportProductRepository } from '@/domain/report/repositories/report-product.repository';
import { ReportProductService } from '@/core/services/api/report-product.service';

@Injectable({
  providedIn: 'root',
})
export class ReportProductRepositoryService implements ReportProductRepository {
  constructor(private reportProductService: ReportProductService) {}

  getProduct(
    reportListQueryParams: ReportGetProductQueryParamsDto
  ): Observable<ResponseReportDto> {
    return this.reportProductService.getData(reportListQueryParams);
  }
}
