import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  BodyPersonProductRevisionDto,
  BodyPersonProductReasignDto,
  BodyPersonProductStateDto,
  BodyPersonProductStateMassiveDto,
  BodyPersonProductUpdateEndDateDto,
  ParamsPersonProductGetAllDto,
  ResponsePersonProductRevisionDto,
  ResponsePersonProductGetAllDto,
  ResponsePersonProductStateDto,
  ResponsePersonProductStateMassiveDto,
} from '@/domain/person-product/dtos/person-product.dto';
import { UrlBuilder } from './config/url-builder';
import {
  PersonProductPageDto,
  PersonProductPageQueryParamsDto,
} from '@/domain/person-product/dtos/person-product.dto';

@Injectable({
  providedIn: 'root',
})
export class PersonProductService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('person/product');
  }

  getPage(
    params: PersonProductPageQueryParamsDto
  ): Observable<PersonProductPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<PersonProductPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getAll(
    params: ParamsPersonProductGetAllDto,
    headers?: Record<string, string>,
  ): Observable<ResponsePersonProductGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<any>(queryUrl, { params, headers: headers });
  }

  endDate(body: BodyPersonProductUpdateEndDateDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/${body.id}/date`;
    return this.http.patch<any>(queryUrl, body);
  }

  revision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto> {
    const queryUrl = `${this.getApiUrl()}/${body.idsubproduct}/revision`;
    return this.http.post<ResponsePersonProductRevisionDto>(queryUrl, body);
  }

  updateRevision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto> {
    const queryUrl = `${this.getApiUrl()}/revision/${body.idsubproduct}`;
    return this.http.put<ResponsePersonProductRevisionDto>(queryUrl, body);
  }

  stateMassive(
    body: BodyPersonProductStateMassiveDto
  ): Observable<ResponsePersonProductStateMassiveDto> {
    const queryUrl = `${this.getApiUrl()}/request/${body.id}/state`;
    return this.http.patch<any>(queryUrl, body);
  }

  reasign(body: any): Observable<BodyPersonProductReasignDto> {
    const queryUrl = `${this.getApiUrl()}/${body.requestId}/reasign`;
    return this.http.patch<any>(queryUrl, body);
  }

  showRevision(id: any): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/revision/${id}`;
    return this.http.get<any>(queryUrl);
  }

  state(
    body: BodyPersonProductStateDto
  ): Observable<ResponsePersonProductStateDto> {
    const queryUrl = `${this.getApiUrl()}/${body.id}/state`;
    return this.http.patch<any>(queryUrl, body);
  }
}
