import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  PendingCreateDto,
  PendingDto,
  PendingEditDto,
  PendingEditStateDto,
  PendingPageQueryParamsDto,
  PendingPageDto,
  PendingShowDto,
} from '@/domain/pending/dtos/pending.dto';

@Injectable({
  providedIn: 'root',
})
export class PendingService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('activity/request');
  }

  create(pending: PendingCreateDto): Observable<PendingDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<PendingDto>(queryUrl, pending);
  }

  getAll(params: any): Observable<PendingDto[]> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<PendingDto[]>(queryUrl, {
      params,
    });
  }

  getPage(params: PendingPageQueryParamsDto): Observable<PendingPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<PendingPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  updateState(pending: PendingEditStateDto): Observable<PendingDto> {
    const queryUrl = `${this.getApiUrl()}/${pending.id}/state`;
    return this.http.patch<PendingDto>(queryUrl, pending);
  }

  show(pendingId: number): Observable<PendingShowDto> {
    const queryUrl = `${this.getApiUrl()}/show/${pendingId}`;
    return this.http.get<PendingShowDto>(queryUrl);
  }

  update(pending: PendingEditDto): Observable<PendingDto> {
    const queryUrl = `${this.getApiUrl()}/${pending.id}`;
    return this.http.put<PendingDto>(queryUrl, pending);
  }
}
