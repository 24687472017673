<div class="layout-topbar">
  <button
    #menuButton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <ng-container *ngIf="pageHeader$ | async as pageHeader">
    <div class="flex flex-column page-info">
      <div class="text-primary mb-1">
        <i
          *ngIf="backRoute$ | async as backRoute"
          class="pi pi-chevron-left text-2xl cursor-pointer"
          [routerLink]="backRoute"
        ></i>
        <span class="text-3xl md:text-4xl font-medium">
          {{ pageHeader.title }}
        </span>
      </div>
      <p-breadcrumb
        class="max-w-full hidden md:block"
        [model]="pageHeader.breadCrumbs"
      >
        <ng-template pTemplate="separator"> / </ng-template>
      </p-breadcrumb>
    </div>
  </ng-container>

  <div class="ml-auto">
    <button
      pButton
      pRipple
      type="button"
      class="p-button-text flex gap-2 align-items-center cursor-pointer"
      (click)="topBarMenu.toggle($event)"
    >
      <span>
        <p-avatar
          [label]="usernameAvatar"
          class="text-white"
          [style]="{ height: '40px', width: '40px' }"
          [styleClass]="'bg-primary'"
          shape="circle"
        />
      </span>
      <span class="font-medium text-lg text-black-alpha-90 hidden md:block">
        {{ currentUsername }}
      </span>
      <span class="ml-1">
        <i class="pi pi-chevron-down text-sm"></i>
      </span>
    </button>
  </div>
</div>

<p-menu
  #topBarMenu
  [model]="headerMenuItems"
  [popup]="true"
  styleClass="left-auto"
  [style]="{ right: '30px' }"
/>
