import { OnInit, signal } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../../../core/services/app.layout.service';
import { CommonModule } from '@angular/common';
import { RppLayoutMenuitemComponent } from '../../molecules/layout-menu-item/layout-menu-item.component';
import { Menu } from '../../../shared/utils/constants/main-menu.constants';
import { LocalStorageService } from '@/core/services/localstorage.service';
import LocalStorageKey from '@/shared/utils/constants/local-storage-key.constants';

@Component({
  selector: 'rpp-menu',
  standalone: true,
  templateUrl: './layout-menu.component.html',
  imports: [RppLayoutMenuitemComponent, CommonModule],
})
export class RppLayoutMenuComponent implements OnInit {
  model = signal<Menu[]>([]);
  ROUTE_MENUS = signal<any>([]);

  constructor(
    public layoutService: LayoutService,
    private localStorageService: LocalStorageService
  ) {
    if (!this.currentUserData) return;
    this.ROUTE_MENUS.set(this.currentUserData.capabilityPermissionsV3);
  }

  ngOnInit() {
    this.model.set([{ items: this.ROUTE_MENUS() }]);
  }

  private get currentUserData() {
    return this.localStorageService.getLocalStorageItem(LocalStorageKey.USER);
  }
}
