import { Injectable } from '@angular/core';
import { UrlBuilder } from './config/url-builder';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CustomerCreateDto,
  CustomerEditDto,
  CustomerGetAllDto,
  CustomerListQueryParamsDto,
  CustomerPageDto,
} from '@/domain/customer/dtos/customer.dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('user/customer');
  }

  create(customer: CustomerCreateDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<any>(queryUrl, customer);
  }

  getPage(params: CustomerListQueryParamsDto): Observable<CustomerPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<CustomerPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getPageExternal(
    params: CustomerListQueryParamsDto
  ): Observable<CustomerPageDto> {
    const queryUrl = `${this.getApiUrl()}/list/external`;
    return this.http.get<CustomerPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  update(customer: CustomerEditDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/${customer.id}`;
    return this.http.put<any>(queryUrl, customer);
  }

  getAll(params?: CustomerListQueryParamsDto): Observable<CustomerGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<CustomerGetAllDto>(queryUrl, {
      params: {
        ...params,
        take: 999,
      },
    });
  }
}
