import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import { CoreFlowPageDto } from '@/domain/core-flow/dtos/core-flow.dto';

@Injectable({
  providedIn: 'root',
})
export class CoreFlowService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('core/flow');
  }

  getAll(): Observable<CoreFlowPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`; //* Solo habia /list
    return this.http.get<CoreFlowPageDto>(queryUrl, {
      params: {
        PageNumber: 0,
        PageSize: 999,
        OrderField: 'Id',
      },
    });
  }
}
