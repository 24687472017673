import { Injectable } from '@angular/core';
import { UrlBuilder } from './config/url-builder';
import { HttpClient } from '@angular/common/http';
import {
  FileUploadDto,
  FileUploadRequestDto,
  ResponseDownloadUpload,
  ResponseUploadShow,
} from '@/domain/files/dtos/files.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('file');
  }

  upload(data: FileUploadDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/upload`;
    const formData: FormData = new FormData();
    formData.append('code', data.code);
    formData.append('file', data.file);
    formData.append('isCampaign', data.isCampaign.toString());
    formData.append('entityId', data.entityId.toString());
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('isdeliverable', data.isdeliverable?.toString() ?? '');

    return this.http.post<any>(queryUrl, formData);
  }

  download(id: string): Observable<ResponseDownloadUpload> {
    const queryUrl = `${this.getApiUrl()}/download/${id}`;
    return this.http.get<ResponseDownloadUpload>(queryUrl);
  }

  show(id: string): Observable<ResponseUploadShow> {
    const queryUrl = `${this.getApiUrl()}/show/${id}`;
    return this.http.get<ResponseUploadShow>(queryUrl);
  }

  uploadRequest(body: FileUploadRequestDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/upload-request`;
    const formData: FormData = new FormData();

    formData.append('file', body.file);
    if (body.id) {
      formData.append('id', body.id.toString());
    }

    return this.http.post<any>(queryUrl, formData);
  }
}
