import {
  ReportGetRequestQueryParamsDto,
  ResponseReportDto,
} from '@/domain/report/dtos/report.dto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('report');
  }

  getData(
    params: ReportGetRequestQueryParamsDto
  ): Observable<ResponseReportDto> {
    const queryUrl = `${this.getApiUrl()}/get/request`;
    return this.http.get<ResponseReportDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }
}
