import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  ActivityTicketCreateDto,
  ActivityTicketDto,
  ActivityTicketEditDto,
  ActivityTicketPageDto,
  ActivityTicketPageQueryParamsDto,
} from '@/domain/activity-ticket/dtos/activity-ticket.dto';

@Injectable({
  providedIn: 'root',
})
export class ActivityTicketService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('activity/ticket');
  }

  create(
    activityTicket: ActivityTicketCreateDto
  ): Observable<ActivityTicketDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<ActivityTicketDto>(queryUrl, activityTicket);
  }

  getPage(
    params: ActivityTicketPageQueryParamsDto
  ): Observable<ActivityTicketPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<ActivityTicketPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getAll(): Observable<ActivityTicketPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`; //* Solo habia /list
    return this.http.get<ActivityTicketPageDto>(queryUrl, {
      params: {
        PageNumber: 0,
        PageSize: 999,
        OrderField: 'Id',
      },
    });
  }

  update(activityTicket: ActivityTicketEditDto): Observable<ActivityTicketDto> {
    const queryUrl = `${this.getApiUrl()}/${activityTicket.id}`;
    return this.http.put<ActivityTicketDto>(queryUrl, activityTicket);
  }

  delete(activityTicket: { id: number }): Observable<ActivityTicketDto> {
    const queryUrl = `${this.getApiUrl()}/${activityTicket.id}`;
    return this.http.delete<ActivityTicketDto>(queryUrl);
  }
}
