import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportRepository } from '@/domain/report/repositories/report.repository';
import { ReportService } from '@/core/services/api/report.service';
import {
  ReportGetRequestQueryParamsDto,
  ResponseReportDto,
} from '@/domain/report/dtos/report.dto';

@Injectable({
  providedIn: 'root',
})
export class ReportRepositoryService implements ReportRepository {
  constructor(private reportService: ReportService) {}

  getRequest(
    reportListQueryParams: ReportGetRequestQueryParamsDto
  ): Observable<ResponseReportDto> {
    return this.reportService.getData(reportListQueryParams);
  }
}
