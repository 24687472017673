import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRepository } from '@/domain/user/repositories/user.repository';
import {
  UserCreateDto,
  UserDto,
  UserEditDto,
  UserEditStateDto,
  UserPageQueryParamsDto,
  UserPageDto,
  UserShowDto,
  UserGetAllDto,
} from '@/domain/user/dtos/user.dto';
import { UserService } from '@/core/services/api/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserRepositoryService implements UserRepository {
  constructor(private userService: UserService) {}
  create(user: UserCreateDto): Observable<UserDto> {
    return this.userService.create(user);
  }

  getPage(params: UserPageQueryParamsDto): Observable<UserPageDto> {
    return this.userService.getPage(params);
  }

  getAll(params: any = {}): Observable<UserGetAllDto> {
    return this.userService.getAll(params);
  }

  updateState(user: UserEditStateDto): Observable<UserDto> {
    return this.userService.updateState(user);
  }

  show(userId: number): Observable<UserShowDto> {
    return this.userService.show(userId);
  }

  update(user: UserEditDto): Observable<UserDto> {
    return this.userService.update(user);
  }
}
