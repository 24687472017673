import { ActivityTicketService } from '@/core/services/api/activity-ticket.service';
import {
  ActivityTicketCreateDto,
  ActivityTicketDto,
  ActivityTicketEditDto,
  ActivityTicketPageDto,
  ActivityTicketPageQueryParamsDto,
} from '@/domain/activity-ticket/dtos/activity-ticket.dto';
import { ActivityTicketRepository } from '@/domain/activity-ticket/repository/activity-ticket.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityTicketRepositoryService
  implements ActivityTicketRepository
{
  constructor(private activityTicketService: ActivityTicketService) {}
  create(
    activityTicket: ActivityTicketCreateDto
  ): Observable<ActivityTicketDto> {
    return this.activityTicketService.create(activityTicket);
  }

  getPage(
    params: ActivityTicketPageQueryParamsDto
  ): Observable<ActivityTicketPageDto> {
    return this.activityTicketService.getPage(params);
  }

  getAll(): Observable<ActivityTicketPageDto> {
    return this.activityTicketService.getAll();
  }

  update(activityTicket: ActivityTicketEditDto): Observable<ActivityTicketDto> {
    return this.activityTicketService.update(activityTicket);
  }

  delete(activityTicket: { id: number }): Observable<ActivityTicketDto> {
    return this.activityTicketService.delete(activityTicket);
  }
}
