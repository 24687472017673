import { MODAL_KEY } from '@/shared/utils/constants/modal-key.constants';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'rpp-modal-confirmation-without-close',
  standalone: true,
  imports: [CommonModule, ConfirmDialogModule, AutoFocusModule],
  templateUrl: './modal-confirmation-without-close.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppModalConfirmationWithoutCloseComponent {
  readonly KEY = MODAL_KEY.CONFIRMATION_MODAL_WITHOUT_CLOSE;
}
