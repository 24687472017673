import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import { ReportGetRequestQueryParamsDto } from '@/domain/report/dtos/report.dto';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('report');
  }

  getData(params: ReportGetRequestQueryParamsDto): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/get/request/download`;
    return this.http.get<any>(queryUrl, {
      responseType: 'blob' as 'json',
      params: {
        ...params,
      },
    });
  }
}
