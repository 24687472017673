import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ReportGetRequestQueryParamsDto,
  ResponseReportDto,
} from '@/domain/report/dtos/report.dto';
import { ReportDownloadRepository } from '@/domain/report/repositories/report-download.repository';
import { ReportDownloadService } from '@/core/services/api/report-download.service';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadRepositoryService
  implements ReportDownloadRepository
{
  constructor(private reportDownloadService: ReportDownloadService) {}

  getRequestDownload(
    reportListQueryParams: ReportGetRequestQueryParamsDto
  ): any {
    return this.reportDownloadService.getData(reportListQueryParams);
  }
}
