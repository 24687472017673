import { PersonProductService } from '@/core/services/api/person-product.service';
import {
  BodyPersonProductRevisionDto,
  ParamsPersonProductGetAllDto,
  ResponsePersonProductRevisionDto,
  PersonProductPageDto,
  PersonProductPageQueryParamsDto,
  ResponsePersonProductGetAllDto,
} from '@/domain/person-product/dtos/person-product.dto';
import { PersonProductRepository } from '@/domain/person-product/repositories/person-product.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PersonProductRepositoryService implements PersonProductRepository {
  constructor(private personProductService: PersonProductService) {}

  getPage(
    params: PersonProductPageQueryParamsDto
  ): Observable<PersonProductPageDto> {
    return this.personProductService.getPage(params);
  }

  getAll(
    params: ParamsPersonProductGetAllDto,
    headers?: Record<string, string>,
  ): Observable<ResponsePersonProductGetAllDto> {
    return this.personProductService.getAll(params, headers);
  }

  endDate(body: any): Observable<any> {
    return this.personProductService.endDate(body);
  }

  revision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto> {
    return this.personProductService.revision(body);
  }
  updateRevision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto> {
    return this.personProductService.updateRevision(body);
  }

  stateMassive(body: any): Observable<any> {
    return this.personProductService.stateMassive(body);
  }

  reasign(body: any): Observable<any> {
    return this.personProductService.reasign(body);
  }

  showRevision(body: any): Observable<any> {
    return this.personProductService.showRevision(body.id);
  }

  state(body: any): Observable<any> {
    return this.personProductService.state(body);
  }
}
