import { CustomerService } from '@/core/services/api/customer.service';
import {
  CustomerCreateDto,
  CustomerEditDto,
  CustomerGetAllDto,
  CustomerListQueryParamsDto,
  CustomerPageDto,
} from '@/domain/customer/dtos/customer.dto';
import { CustomerRepository } from '@/domain/customer/repositories/customer.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerRepositoryService implements CustomerRepository {
  constructor(private customerService: CustomerService) {}

  create(customer: CustomerCreateDto): Observable<any> {
    return this.customerService.create(customer);
  }

  getPage(
    customerListQueryParams: CustomerListQueryParamsDto
  ): Observable<CustomerPageDto> {
    return this.customerService.getPage(customerListQueryParams);
  }

  update(customer: CustomerEditDto): Observable<any> {
    return this.customerService.update(customer);
  }

  getAll(
    customer?: CustomerListQueryParamsDto | undefined
  ): Observable<CustomerGetAllDto> {
    return this.customerService.getAll(customer);
  }

  getPageExternal(
    customer: CustomerListQueryParamsDto
  ): Observable<CustomerPageDto> {
    return this.customerService.getPageExternal(customer);
  }
}
