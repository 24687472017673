import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  NoWorkingDayCreateDto,
  NoWorkingDayDto,
  NoWorkingDayEditDto,
  NoWorkingDayPageDto,
  NoWorkingDayPageQueryParamsDto,
} from '@/domain/noworking-day/dtos/noworking-day.dto';

@Injectable({
  providedIn: 'root',
})
export class NoWorkingDayService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('noworking/day');
  }

  create(workStation: NoWorkingDayCreateDto): Observable<NoWorkingDayDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<NoWorkingDayDto>(queryUrl, workStation);
  }

  getPage(
    params: NoWorkingDayPageQueryParamsDto
  ): Observable<NoWorkingDayPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<NoWorkingDayPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  update(workStation: NoWorkingDayEditDto): Observable<NoWorkingDayDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.put<NoWorkingDayDto>(queryUrl, workStation);
  }

  delete(workStation: { id: number }): Observable<NoWorkingDayDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.delete<NoWorkingDayDto>(queryUrl);
  }
}
