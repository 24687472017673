import { type HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/api/auth.service';
import { inject } from '@angular/core';
import { isVarEmpty } from '@/shared/utils/functions/general.functions';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  const token: string = authService.currentToken?.token;

  let modifiedReq = req.clone();

  if (!isVarEmpty(token)) {
    modifiedReq = modifiedReq.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  if (!isVarEmpty(modifiedReq.headers.has('Content-Type'))) {
    modifiedReq = modifiedReq.clone({
      setHeaders: { 'Content-Type': 'application/json; charset=UTF-8' },
    });
  }

  return next(modifiedReq);
};
