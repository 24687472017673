import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardRepository } from "@/domain/dashboard/repositories/dashboard.repository";
import { DashboardService } from '@/core/services/api/dashboard.service';
import { DashboardGetQueryParamsDto, ResponseDashboardDto } from '@/domain/dashboard/dtos/dashboard.dto';

@Injectable({
  providedIn: 'root',
})
export class DashboardRepositoryService implements DashboardRepository {
  constructor(private dashboardService: DashboardService) {}

  get(
    dashboardListQueryParams: DashboardGetQueryParamsDto
  ): Observable<ResponseDashboardDto> {
    return this.dashboardService.getData(dashboardListQueryParams);
  }
}
