import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PendingRepository } from '@/domain/pending/repositories/pending.repository';
import {
  PendingCreateDto,
  PendingDto,
  PendingEditDto,
  PendingEditStateDto,
  PendingPageQueryParamsDto,
  PendingPageDto,
  PendingShowDto,
} from '@/domain/pending/dtos/pending.dto';
import { PendingService } from '@/core/services/api/pending.service';

@Injectable({
  providedIn: 'root',
})
export class PendingRepositoryService implements PendingRepository {
  constructor(private pendingService: PendingService) {}
  create(pending: PendingCreateDto): Observable<PendingDto> {
    return this.pendingService.create(pending);
  }

  getPage(params: PendingPageQueryParamsDto): Observable<PendingPageDto> {
    return this.pendingService.getPage(params);
  }

  getAll(params: any): Observable<PendingDto[]> {
    return this.pendingService.getAll(params);
  }

  updateState(pending: PendingEditStateDto): Observable<PendingDto> {
    return this.pendingService.updateState(pending);
  }

  show(pendingId: number): Observable<PendingShowDto> {
    return this.pendingService.show(pendingId);
  }

  update(pending: PendingEditDto): Observable<PendingDto> {
    return this.pendingService.update(pending);
  }
}
