import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductRepository } from '@/domain/product/repositories/product.repository';
import {
  ProductGetAllDto,
  ProductPageDto,
  ProductPageQueryParamsDto,
  ResponseProductShowDto,
} from '@/domain/product/dtos/product.dto';
import { ProductService } from '@/core/services/api/product.service';

@Injectable({
  providedIn: 'root',
})
export class ProductRepositoryService implements ProductRepository {
  constructor(private productService: ProductService) {}
  getPage(params: ProductPageQueryParamsDto): Observable<ProductPageDto> {
    return this.productService.getPage(params);
  }

  getAll(params: any = {}): Observable<ProductGetAllDto> {
    return this.productService.getAll(params);
  }

  state(body: any): Observable<any> {
    return this.productService.state(body);
  }

  show(id: number): Observable<ResponseProductShowDto> {
    return this.productService.show(id);
  }
}
