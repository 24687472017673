import { CoreFlowService } from '@/core/services/api/core-flow.service';
import { CoreFlowPageDto } from '@/domain/core-flow/dtos/core-flow.dto';
import { CoreFlowRepository } from '@/domain/core-flow/repositories/core-flow.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoreFlowRepositoryService implements CoreFlowRepository {
  constructor(private taxonomyCategoryService: CoreFlowService) {}

  getAll(): Observable<CoreFlowPageDto> {
    return this.taxonomyCategoryService.getAll();
  }
}
