import { UploadService } from '@/core/services/api/upload.service';
import {
  FileUploadDto,
  FileUploadRequestDto,
  ResponseDownloadUpload,
  ResponseUploadShow,
} from '@/domain/files/dtos/files.dto';
import { UploadRepository } from '@/domain/files/repositories/upload.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadRepositoryService implements UploadRepository {
  constructor(private uploadService: UploadService) {}

  upload(body: FileUploadDto): Observable<any> {
    return this.uploadService.upload(body);
  }

  download(id: string): Observable<ResponseDownloadUpload> {
    return this.uploadService.download(id);
  }

  show(id: string): Observable<ResponseUploadShow> {
    return this.uploadService.show(id);
  }

  uploadRequest(body: FileUploadRequestDto): Observable<any> {
    return this.uploadService.uploadRequest(body);
  }
}
