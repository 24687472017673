import { Component, ElementRef, HostBinding } from '@angular/core';
import { LayoutService } from '../../../core/services/app.layout.service';
import { CommonModule } from '@angular/common';
import { RppLayoutMenuComponent } from '../layout-menu/layout-menu.component';
import { RPPLogoImageComponent } from '../../atoms/images/logo/logo.component';

@Component({
  selector: 'rpp-layout-sidebar',
  standalone: true,
  templateUrl: './layout-sidebar.component.html',
  imports: [CommonModule, RppLayoutMenuComponent, RPPLogoImageComponent],
})
export class RppLayoutSidebarComponent {
  @HostBinding('class') class = 'layout-sidebar';

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef
  ) {}
}
