import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthRepository } from '../../../domain/auth/repositories/auth.repository';
import { AuthResponseDto } from '../../../domain/auth/dtos/auth.dto';
import { AuthService } from '../../../core/services/api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRepositoryService implements AuthRepository {
  constructor(private authService: AuthService) {}

  login(username: string, password: string): Observable<AuthResponseDto> {
    return this.authService.login({ username, password });
  }
}
