import { Injectable } from '@angular/core';
import { UrlBuilder } from './config/url-builder';
import {
  BodyProductStateDto,
  ProductDto,
  ProductGetAllDto,
  ProductPageDto,
  ProductPageQueryParamsDto,
  ResponseProductShowDto,
  ResponseProductStateDto,
} from '@/domain/product/dtos/product.dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('request/product');
  }

  getAll(params: any = {}): Observable<ProductGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<ProductGetAllDto>(queryUrl, {
      params,
    });
  }

  getPage(params: ProductPageQueryParamsDto): Observable<ProductPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<ProductPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  state(body: BodyProductStateDto): Observable<ResponseProductStateDto> {
    const queryUrl = `${this.getApiUrl()}/${body.id}/state`;
    return this.http.patch<any>(queryUrl, body);
  }

  show(id: number): Observable<ResponseProductShowDto> {
    const queryUrl = `${this.getApiUrl()}/show/${id}`;
    return this.http.get<ResponseProductShowDto>(queryUrl);
  }
}
