import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  GetAllTaxonomyCategoryParams,
  TaxonomyCategoryCreateDto,
  TaxonomyCategoryDto,
  TaxonomyCategoryEditDto,
  TaxonomyCategoryGetAllDto,
  TaxonomyCategoryPageDto,
  TaxonomyCategoryPageQueryParamsDto,
  TaxonomyCategoryShowDto,
} from '@/domain/taxonomy-category/dtos/taxonomy-category.dto';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyCategoryService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('taxonomy/category');
  }

  create(
    taxonomyCategory: TaxonomyCategoryCreateDto
  ): Observable<TaxonomyCategoryDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<TaxonomyCategoryDto>(queryUrl, taxonomyCategory);
  }

  getPage(
    params: TaxonomyCategoryPageQueryParamsDto
  ): Observable<TaxonomyCategoryPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<TaxonomyCategoryPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getAll(
    params?: GetAllTaxonomyCategoryParams
  ): Observable<TaxonomyCategoryGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get?take=999`;
    return this.http.get<TaxonomyCategoryGetAllDto>(queryUrl, {
      params: { ...params },
    });
  }

  show(params: any): Observable<TaxonomyCategoryShowDto> {
    const queryUrl = `${this.getApiUrl()}/show/${params.id}`;
    return this.http.get<TaxonomyCategoryShowDto>(queryUrl, {
      params: { ...params },
    });
  }

  update(
    taxonomyCategory: TaxonomyCategoryEditDto
  ): Observable<TaxonomyCategoryDto> {
    const queryUrl = `${this.getApiUrl()}/${taxonomyCategory.id}`;
    return this.http.put<TaxonomyCategoryDto>(queryUrl, taxonomyCategory);
  }

  delete(taxonomyCategory: { id: number }): Observable<TaxonomyCategoryDto> {
    const queryUrl = `${this.getApiUrl()}/${taxonomyCategory.id}`;
    return this.http.delete<TaxonomyCategoryDto>(queryUrl);
  }
}
