import { BinnacleService } from '@/core/services/api/binnacle.service';
import { BinnacleOfEntityResponseDto } from '@/domain/binnacle/dtos/binnacle.dto';
import { BinnacleRepository } from '@/domain/binnacle/repositories/binnacle.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BinnacleRepositoryService implements BinnacleRepository {
  constructor(private binnacleService: BinnacleService) {}

  entityDetail(params: any): Observable<BinnacleOfEntityResponseDto> {
    return this.binnacleService.getOfActivityRequest(params);
  }

  getRejectedAction(params: any): Observable<BinnacleOfEntityResponseDto> {
    return this.binnacleService.getRejectedAction(params);
  }
}
