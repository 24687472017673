import { NoWorkingDayService } from '@/core/services/api/noworking-day.service';
import {
  NoWorkingDayCreateDto,
  NoWorkingDayDto,
  NoWorkingDayEditDto,
  NoWorkingDayPageDto,
  NoWorkingDayPageQueryParamsDto,
} from '@/domain/noworking-day/dtos/noworking-day.dto';
import { NoWorkingDayRepository } from '@/domain/noworking-day/repositories/noworking-day.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoWorkingDayRepositoryService implements NoWorkingDayRepository {
  constructor(private noWorkingDayService: NoWorkingDayService) {}
  create(noWorkingDay: NoWorkingDayCreateDto): Observable<NoWorkingDayDto> {
    return this.noWorkingDayService.create(noWorkingDay);
  }

  getPage(
    params: NoWorkingDayPageQueryParamsDto
  ): Observable<NoWorkingDayPageDto> {
    return this.noWorkingDayService.getPage(params);
  }

  update(noWorkingDay: NoWorkingDayEditDto): Observable<NoWorkingDayDto> {
    return this.noWorkingDayService.update(noWorkingDay);
  }

  delete(noWorkingDay: { id: number }): Observable<NoWorkingDayDto> {
    return this.noWorkingDayService.delete(noWorkingDay);
  }
}
