import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  WorkStationCreateDto,
  WorkStationDto,
  WorkStationEditDto,
  WorkStationGetAllDto,
  WorkStationPageDto,
  WorkStationPageQueryParamsDto,
} from '@/domain/work-station/dtos/work-station.dto';

@Injectable({
  providedIn: 'root',
})
export class WorkStationService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('work/station');
  }

  create(workStation: WorkStationCreateDto): Observable<WorkStationDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<WorkStationDto>(queryUrl, workStation);
  }

  getPage(
    params: WorkStationPageQueryParamsDto
  ): Observable<WorkStationPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<WorkStationPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  getAll(): Observable<WorkStationGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<WorkStationGetAllDto>(queryUrl, {
      params: {
        take: 999,
      },
    });
  }

  update(workStation: WorkStationEditDto): Observable<WorkStationDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.put<WorkStationDto>(queryUrl, workStation);
  }

  delete(workStation: { id: number }): Observable<WorkStationDto> {
    const queryUrl = `${this.getApiUrl()}/${workStation.id}`;
    return this.http.delete<WorkStationDto>(queryUrl);
  }
}
