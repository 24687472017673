import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RoleGetAllDto } from '@/domain/role/dtos/role.dto';
import { UrlBuilder } from './config/url-builder';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('capability/role');
  }

  getAll(): Observable<RoleGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<RoleGetAllDto>(queryUrl);
  }
}
