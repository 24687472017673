<p-confirmDialog #cd [key]="KEY" [blockScroll]="true">
  <ng-template pTemplate="headless" let-context>
    <div
      class="flex flex-column align-items-center p-4 surface-overlay border-round relative w-27rem"
    >
      <div class="mt-3">
        <i [class]="context.icon" class="text-6xl"></i>
      </div>

      <div class="font-medium text-xl mb-2 mt-4 mx-4 text-center line-height-3">
        {{ context.header }}
      </div>

      <div class="mx-6 text-center line-height-3">{{ context.message }}</div>

      <div
        class="flex flex-column align-items-center justify-content-center gap-2 mt-4 w-full"
      >
        <p-button
          *ngIf="context.acceptVisible"
          [label]="context.acceptLabel"
          class="w-full"
          styleClass="w-full justify-content-center"
          [autofocus]="true"
          pAutoFocus
          (click)="cd.accept()"
        />
        <p-button
          *ngIf="context.rejectVisible"
          [label]="context.rejectLabel"
          class="w-full"
          styleClass="w-full justify-content-center"
          [outlined]="true"
          (click)="cd.reject()"
        />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
