import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  RequestCreateDto,
  RequestDto,
  RequestEditDto,
  RequestPageQueryParamsDto,
  RequestPageDto,
  RequestShowDto,
  RequestGetAllDto,
  RequestNotificationBodyDto,
  ResponseNotificationDto,
  RequestEditStateDto,
} from '@/domain/request/dtos/request.dto';

@Injectable({
  providedIn: 'root',
})
export class RequestService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('activity/request');
  }

  create(request: RequestCreateDto): Observable<RequestDto> {
    const queryUrl = `${this.getApiUrl()}`;
    return this.http.post<RequestDto>(queryUrl, request);
  }

  getAll(params: any = {}): Observable<RequestGetAllDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<RequestGetAllDto>(queryUrl, {
      params,
    });
  }

  getPage(params: RequestPageQueryParamsDto): Observable<RequestPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;

    return this.http.get<RequestPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  updateState(request: RequestEditStateDto): Observable<void> {
    const queryUrl = `${this.getApiUrl()}/${request.id}/state`;
    return this.http.patch<void>(queryUrl, request);
  }

  show(requestId: number): Observable<RequestShowDto> {
    const queryUrl = `${this.getApiUrl()}/show/${requestId}`;
    return this.http.get<RequestShowDto>(queryUrl);
  }

  update(
    request: RequestEditDto,
    headers?: Record<string, string>
  ): Observable<RequestDto> {
    const queryUrl = `${this.getApiUrl()}/${request.id}`;
    return this.http.put<RequestDto>(queryUrl, request, {
      headers,
    });
  }

  notification(
    body: RequestNotificationBodyDto
  ): Observable<ResponseNotificationDto> {
    const queryUrl = `${this.getApiUrl()}/${body.requestId}/notification`;
    return this.http.post<ResponseNotificationDto>(queryUrl, body);
  }

  delete(id: number): Observable<void> {
    const queryUrl = `${this.getApiUrl()}/${id}`;
    return this.http.delete<void>(queryUrl);
  }
}
