import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from '../../../core/services/app.layout.service';
import { MenuModule } from 'primeng/menu';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { AuthService } from '@/core/services/api/auth.service';
import { HeaderDataService } from '@/core/services/headerData.service';
import { PageHeader } from '@/shared/utils/constants/page-header.constants';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import LocalStorageKey from '@/shared/utils/constants/local-storage-key.constants';
import { LocalStorageService } from '@/core/services/localstorage.service';

@Component({
  selector: 'rpp-topbar',
  standalone: true,
  templateUrl: './layout-topbar.component.html',
  styles: `
    .page-info {
      margin-left: 220px;
    }

    @media (max-width: 990px) {
      .page-info {
        margin-left: 25px;
      }
    }
  `,
  imports: [
    MenuModule,
    CommonModule,
    AvatarModule,
    BreadcrumbModule,
    ButtonModule,
  ],
})
export class RppLayoutTopBarComponent {
  readonly headerMenuItems: MenuItem[] = [
    {
      label: 'Cerrar sesión',
      icon: 'pi pi-sign-out text-primary',
      command: () => {
        this.authService.logout();
      },
    },
  ];

  @ViewChild('menuButton') menuButton!: ElementRef;

  pageHeader$: Observable<PageHeader>;
  backRoute$: Observable<string>;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private headerDataService: HeaderDataService,
    private localStorageService: LocalStorageService
  ) {
    this.pageHeader$ = this.headerDataService.pageHeader$;
    this.backRoute$ = this.headerDataService.builtBackRoute$;
  }

  private get currentUserData() {
    return this.localStorageService.getLocalStorageItem(LocalStorageKey.USER);
  }

  get currentUsername(): string {
    if (!this.currentUserData) return '';

    const { firstname, lastname } = this.currentUserData;

    return `${firstname}  ${lastname}`;
  }

  get usernameAvatar(): string {
    if (!this.currentUserData) return '';

    const { firstname, lastname } = this.currentUserData; // Destructuración con valor predeterminado
    const initialFirstname = firstname?.[0]?.toUpperCase() || '';
    const initialLastname = lastname?.[0]?.toUpperCase() || '';

    return `${initialFirstname}${initialLastname}`;
  }
}
