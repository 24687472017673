import { ActivityTicketProvider } from './activity-ticket/providers/activity-ticket.provider';
import { AuditProvider } from './audit/providers/audit.provider';
import { AuthProviders } from './auth/providers/auth.provider';
import { BinnacleProvider } from './binnacle/providers/binnacle.provider';
import { CoreFlowProvider } from './core-flow/providers/core-flow.provider';
import { CustomerProvider } from './customer/providers/customer.provider';
import { DashboardProvider } from './dashboard/providers/dashboard.provider';
import { NoWorkingDayProvider } from './noworking-day/providers/noworking-day.provider';
import { PendingProviders } from './pending/providers/user.provider';
import { PersonProductProviders } from './person-product/providers/person-product.provider';
import { ProductProviders } from './product/providers/product.provider';
import { ReportProvider } from './report/providers/audit.provider';
import { ReportProductDownloadProvider } from './report/providers/product-report-download.provider';
import { ReportProductProvider } from './report/providers/product-report.provider';
import { ReportDownloadProvider } from './report/providers/request-report-download.provider';
import { RequestProvider } from './request/providers/request.provider';
import { RoleProvider } from './role/providers/role.provider';
import { TaxonomyCategoryProvider } from './taxonomy-category/providers/taxonomy-category.provider';
import { UploadProvider } from './upload/providers/upload.provider';
import { UserProviders } from './user/providers/user.provider';
import { WorkStationProvider } from './work-station/providers/work-station.provider';
import { WorkJobProvider } from './workjob/providers/workjob.provider';

export const infrastructureProviders = [
    ...AuthProviders,
    ...UserProviders,
    ...CustomerProvider,
    ...AuditProvider,
    ...WorkStationProvider,
    ...WorkJobProvider,
    ...NoWorkingDayProvider,
    ...TaxonomyCategoryProvider,
    ...ActivityTicketProvider,
    ...CoreFlowProvider,
    ...RoleProvider,
    ...PendingProviders,
    ...RequestProvider,
    ...ProductProviders,
    ...PersonProductProviders,
    ...BinnacleProvider,
    ...DashboardProvider,
    ...ReportProvider,
    ...ReportProductProvider,
    ...ReportDownloadProvider,
    ...ReportProductDownloadProvider,
    ...UploadProvider,
];
