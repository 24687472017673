import {
  BAD_REQUEST_STATUS_CODES,
  CONFLICT_STATUS_CODES,
  FORBBIDEN_STATUS_CODES,
  NOT_FOUND_STATUS_CODES,
  PRECONDITION_FAILED_STATUS_CODES,
  UNAUTHORIZED_STATUS_CODES,
} from '../constants/http.constants';

export const checkIfUnauthorizedStatus = (httpErrorCode: number) =>
  UNAUTHORIZED_STATUS_CODES.includes(httpErrorCode);
export const checkIfForbiddenStatus = (httpErrorCode: number) =>
  FORBBIDEN_STATUS_CODES.includes(httpErrorCode);
export const checkIfBadRequestStatus = (httpErrorCode: number) =>
  BAD_REQUEST_STATUS_CODES.includes(httpErrorCode);
export const checkIfNotFoundStatus = (httpErrorCode: number) =>
  NOT_FOUND_STATUS_CODES.includes(httpErrorCode);
export const checkIfConflictStatus = (httpErrorCode: number) =>
  CONFLICT_STATUS_CODES.includes(httpErrorCode);
export const checkIfPreConditionFailedStatus = (httpErrorCode: number) =>
  PRECONDITION_FAILED_STATUS_CODES.includes(httpErrorCode);
export const checkIfServerErrorStatus = (httpErrorCode: number) =>
  httpErrorCode >= 500;

export const checkErrorStatus = (httpErrorCode: number) => ({
  isUnauthorized: checkIfUnauthorizedStatus(httpErrorCode),
  isForbidden: checkIfForbiddenStatus(httpErrorCode),
  isBadRequest: checkIfBadRequestStatus(httpErrorCode),
  isNotFound: checkIfNotFoundStatus(httpErrorCode),
  isConflict: checkIfConflictStatus(httpErrorCode),
  isPreconditionFailed: checkIfPreConditionFailedStatus(httpErrorCode),
  isServerError: checkIfServerErrorStatus(httpErrorCode),
});
