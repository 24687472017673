import { checkErrorStatus } from '@/shared/utils/functions/http.functions';
import type {
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import { EMPTY, catchError, map } from 'rxjs';
import { ToastService } from '../services/toast.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/api/auth.service';

export const HandleErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const toastService = inject(ToastService);
  const router = inject(Router);

  let unauthorizedToastShown = false;

  return next(req).pipe(
    map((event) => {
      unauthorizedToastShown = false;

      return event;
    }),
    catchError(({ status, error }: HttpErrorResponse) => {
      if (unauthorizedToastShown) return EMPTY;

      const {
        isUnauthorized,
        isForbidden,
        isBadRequest,
        isNotFound,
        isPreconditionFailed,
        isServerError,
        isConflict,
      } = checkErrorStatus(status);

      if (isBadRequest) {
        toastService.showError({
          summary: 'Error de operación',
          detail: 'La operación no se pudo completar',
        });
        return EMPTY;
      }

      if (isUnauthorized) {
        if (unauthorizedToastShown) return EMPTY;

        if (router.url === '/auth/login') return EMPTY;

        toastService.showError({
          summary: 'Sesion expirada',
          detail: 'Por favor, inicia sesión',
        });

        authService.logout();

        unauthorizedToastShown = true;

        return EMPTY;
      }

      if (isForbidden) {
        toastService.showError({
          summary: 'No autorizado',
          detail: 'No tienes permisos para realizar la operación',
        });
        return EMPTY;
      }

      if (isNotFound) {
        toastService.showError({
          summary: 'Recurso no encontrado',
          detail: 'La operación no se pudo completar',
        });
        return EMPTY;
      }

      if (isPreconditionFailed) {
        toastService.showError({
          summary: 'Precondición fallida',
          detail: 'El recurso ya existe',
        });
        return EMPTY;
      }

      if (isConflict) {
        throw {
          status,
          error,
        };
      }

      if (isServerError) {
        router.navigate(['/error/500']);

        return EMPTY;
      }

      toastService.showError({
        summary: 'Error',
        detail: 'Ocurrió un error inesperado',
      });

      throw {
        status,
        error,
      };
    })
  );
};
