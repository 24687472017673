import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestRepository } from '@/domain/request/repositories/request.repository';
import {
  RequestPageQueryParamsDto,
  RequestPageDto,
  RequestCreateDto,
  RequestDto,
  RequestShowDto,
  RequestEditDto,
  RequestNotificationBodyDto,
  ResponseNotificationDto,
  RequestEditStateDto,
} from '@/domain/request/dtos/request.dto';
import { RequestService } from '@/core/services/api/request.service';

@Injectable({
  providedIn: 'root',
})
export class RequestRepositoryService implements RequestRepository {
  constructor(private requestService: RequestService) {}

  create(request: RequestCreateDto): Observable<RequestDto> {
    return this.requestService.create(request);
  }

  getPage(params: RequestPageQueryParamsDto): Observable<RequestPageDto> {
    return this.requestService.getPage(params);
  }

  // getAll(params: any = {}): Observable<RequestGetAllDto> {
  //   return this.requestService.getAll(params);
  // }

  show(requestId: number): Observable<RequestShowDto> {
    return this.requestService.show(requestId);
  }

  update(
    request: RequestEditDto,
    headers?: Record<string, string>
  ): Observable<RequestDto> {
    return this.requestService.update(request, headers);
  }

  notification(
    body: RequestNotificationBodyDto
  ): Observable<ResponseNotificationDto> {
    return this.requestService.notification(body);
  }

  updateState(request: RequestEditStateDto): Observable<void> {
    return this.requestService.updateState(request);
  }

  delete(id: number): Observable<void> {
    return this.requestService.delete(id);
  }
}
