import { ApplicationConfig } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import {
  QueryClient,
  provideAngularQuery,
} from '@tanstack/angular-query-experimental';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { infrastructureProviders } from './infrastructure/infrastructure';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { HandleErrorInterceptor } from './core/interceptors/handleError.interceptor';
import { PermissionInterceptor } from './core/interceptors/permission.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideAnimationsAsync(),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 5, // 5 segundos
            retry: 1,
          },
        },
      })
    ),
    QueryClient,
    ...infrastructureProviders,
    provideHttpClient(
      withInterceptors([
        AuthInterceptor,
        PermissionInterceptor,
        HandleErrorInterceptor,
      ])
    ),
    MessageService,
    ConfirmationService,
  ],
};
