import {
  DashboardGetQueryParamsDto,
  ResponseDashboardDto,
} from '@/domain/dashboard/dtos/dashboard.dto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('dashboard');
  }

  getData(
    params: DashboardGetQueryParamsDto
  ): Observable<ResponseDashboardDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<ResponseDashboardDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }
}
