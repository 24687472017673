import { type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { getMenuIdByModuleName } from '@/shared/utils/functions/permission.function';
import { RouteDataService } from '../services/routeData.service';

/**
 * Interceptor que agrega el header de permiso de menu a las peticiones.
 */
export const PermissionInterceptor: HttpInterceptorFn = (req, next) => {
  const routeDataService = inject(RouteDataService);

  const PERMISSION_HEADER = 'X-menu';

  let modifiedReq = req.clone();

  const moduleName = routeDataService.getModuleName();

  let menuId: string | null = null;

  if (moduleName) {
    menuId = getMenuIdByModuleName(moduleName);
  }

  modifiedReq = modifiedReq.clone({
    setHeaders: { [PERMISSION_HEADER]: `${menuId}` },
  });

  return next(modifiedReq);
};
