import { WorkStationService } from '@/core/services/api/work-station.service';
import {
  WorkStationCreateDto,
  WorkStationDto,
  WorkStationEditDto,
  WorkStationGetAllDto,
  WorkStationPageDto,
  WorkStationPageQueryParamsDto,
} from '@/domain/work-station/dtos/work-station.dto';
import { WorkStationRepository } from '@/domain/work-station/repositories/work-station.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkStationRepositoryService implements WorkStationRepository {
  constructor(private workStationService: WorkStationService) {}
  create(workStation: WorkStationCreateDto): Observable<WorkStationDto> {
    return this.workStationService.create(workStation);
  }

  getPage(
    params: WorkStationPageQueryParamsDto
  ): Observable<WorkStationPageDto> {
    return this.workStationService.getPage(params);
  }

  getAll(): Observable<WorkStationGetAllDto> {
    return this.workStationService.getAll();
  }

  update(workStation: WorkStationEditDto): Observable<WorkStationDto> {
    return this.workStationService.update(workStation);
  }

  delete(workStation: { id: number }): Observable<WorkStationDto> {
    return this.workStationService.delete(workStation);
  }
}
