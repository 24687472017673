import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import {
  AuditPageDto,
  AuditPageQueryParamsDto,
} from '@/domain/audit/dtos/audit.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('audit');
  }

  getPage(params: AuditPageQueryParamsDto): Observable<AuditPageDto> {
    const queryUrl = `${this.getApiUrl()}/list`;
    return this.http.get<AuditPageDto>(queryUrl, {
      params: {
        ...params,
      },
    });
  }

  show(auditId: number): Observable<any> {
    const queryUrl = `${this.getApiUrl()}/show/${auditId}`;
    return this.http.get<any>(queryUrl);
  }
}
