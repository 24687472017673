import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from './config/url-builder';
import { BinnacleOfEntityResponseDto } from '@/domain/binnacle/dtos/binnacle.dto';

@Injectable({
  providedIn: 'root',
})
export class BinnacleService extends UrlBuilder {
  constructor(private http: HttpClient) {
    super('request/binnacle');
  }

  /**
   * Obtiene los logs de bitacorad de una solicitud
   */
  getOfActivityRequest(params: any): Observable<BinnacleOfEntityResponseDto> {
    const queryUrl = `${this.getApiUrl()}/get`;
    return this.http.get<BinnacleOfEntityResponseDto>(queryUrl, {
      params: {
        ...params,
        take: 3000,
      },
    });
  }

  /**
   * Obtiene los logs de rechazos de bitacora de una solicitud
   */
  getRejectedAction(params: any): Observable<BinnacleOfEntityResponseDto> {
    const queryUrl = `${this.getApiUrl()}/get/without-permission`;
    return this.http.get<BinnacleOfEntityResponseDto>(queryUrl, {
      params: {
        ...params,
        take: 1000,
      },
    });
  }
}
