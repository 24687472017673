import { TaxonomyCategoryService } from '@/core/services/api/taxonomy-category.service';
import {
  TaxonomyCategoryCreateDto,
  TaxonomyCategoryDto,
  TaxonomyCategoryEditDto,
  TaxonomyCategoryGetAllDto,
  TaxonomyCategoryPageDto,
  TaxonomyCategoryPageQueryParamsDto,
  TaxonomyCategoryShowDto,
} from '@/domain/taxonomy-category/dtos/taxonomy-category.dto';
import { TaxonomyCategoryRepository } from '@/domain/taxonomy-category/repositories/taxonomy-category.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyCategoryRepositoryService
  implements TaxonomyCategoryRepository
{
  constructor(private taxonomyCategoryService: TaxonomyCategoryService) {}
  create(
    taxonomyCategory: TaxonomyCategoryCreateDto
  ): Observable<TaxonomyCategoryDto> {
    return this.taxonomyCategoryService.create(taxonomyCategory);
  }

  getPage(
    params: TaxonomyCategoryPageQueryParamsDto
  ): Observable<TaxonomyCategoryPageDto> {
    return this.taxonomyCategoryService.getPage(params);
  }

  getAll(): Observable<TaxonomyCategoryGetAllDto> {
    return this.taxonomyCategoryService.getAll();
  }

  update(
    taxonomyCategory: TaxonomyCategoryEditDto
  ): Observable<TaxonomyCategoryDto> {
    return this.taxonomyCategoryService.update(taxonomyCategory);
  }

  delete(taxonomyCategory: { id: number }): Observable<TaxonomyCategoryDto> {
    return this.taxonomyCategoryService.delete(taxonomyCategory);
  }

  show(params: any): Observable<TaxonomyCategoryShowDto> {
    return this.taxonomyCategoryService.show(params);
  }
}
